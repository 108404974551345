import React, { useState } from 'react'
import {
  RestaurantElevationMutationVariables,
  ApiResponse,
  Deployment
} from '@local/types'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { DeploymentsApi } from '@local/api'
import {
  AddIcon,
  DeleteIcon,
  LockLockedIcon,
  LockUnlockedIcon
} from '@toasttab/buffet-pui-icons'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  filterByActiveDeployments,
  filterByRElevation,
  getDeploymentsQueryKey,
  populateRestaurantElevationList
} from '@local/utils'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  AddRestaurantElevationsModal,
  ConfirmModal,
  DeleteAllRestaurantElevationsModal
} from '@local/service-elevations-modals'
import { Alert } from '@toasttab/buffet-pui-alerts'

export interface RestaurantElevationsTableProps {
  testId?: string | number
  deploymentsByService: Deployment[]
  deploymentsApi: DeploymentsApi
  serviceName: string
}

export interface RestaurantElevationsRowItem {
  revision: number
  deployment: number
  restaurantElevationGuid: string
}

export const RestaurantElevationsTable = (
  props: RestaurantElevationsTableProps
) => {
  const queryClient = useQueryClient()
  const [isRestaurantElevationsModalOpen, setIsRestaurantElevationsModalOpen] =
    useState(false)
  const [isDeleteRElevationModalOpen, setIsDeleteRElevationModalOpen] =
    useState(false)
  const [isDeleteAllRElevationModalOpen, setIsDeleteAllRElevationModalOpen] =
    useState(false)
  const [selectedDeploymentId, setSelectedDeploymentId] = useState<
    string | null
  >(null)
  const [selectedRestaurantGuid, setSelectedRestaurantGuid] = useState<
    string | null
  >(null)
  const [errorMessage, setErrorMessage] = useState('')
  const { showSuccessSnackBar } = useSnackBar()
  const [isLocked, setIsLocked] = useState(true)
  const { deploymentsByService, deploymentsApi, serviceName } = props

  const activeDeployments = filterByActiveDeployments(deploymentsByService)
  const deploymentsWithRElevations = filterByRElevation(activeDeployments)

  const restaurantElevations =
    populateRestaurantElevationList(deploymentsByService)

  function addRestaurantElevations({
    deploymentId,
    restaurantData
  }: RestaurantElevationMutationVariables) {
    return deploymentsApi.addRestaurantElevations(
      serviceName,
      deploymentId,
      restaurantData
    )
  }

  function deleteRestaurantElevations({
    deploymentId,
    restaurantData
  }: RestaurantElevationMutationVariables) {
    return deploymentsApi.deleteRestaurantElevations(
      serviceName,
      deploymentId,
      restaurantData
    )
  }

  function deleteAllRestaurantElevationsForDeployment({
    deploymentId
  }: {
    deploymentId: string
  }) {
    return deploymentsApi.deleteAllRestaurantElevationsForDeployment(
      serviceName,
      deploymentId
    )
  }

  const {
    isLoading: isLoadingAdd,
    isError: isErrorAdd,
    mutate: mutateAddRestaurantElevations,
    reset: resetErrorAdd
  } = useMutation({
    mutationFn: addRestaurantElevations,
    onSuccess: async (response: ApiResponse) => {
      showSuccessSnackBar(response.message)
      queryClient.invalidateQueries(getDeploymentsQueryKey) // invalidate previous data, refetch, and rerender restaurant elevations table
    },
    onError: (err: Error) => {
      const errorText = `An error has occurred while updating the restaurant elevations: ${err.message}`
      console.error(errorText)
      setErrorMessage(errorText)
    }
  })

  const {
    isLoading: isLoadingDeleteAll,
    isError: isErrorDeleteAll,
    mutate: mutateDeleteAllRestaurantElevations,
    reset: resetErrorDeleteAll
  } = useMutation({
    mutationFn: deleteAllRestaurantElevationsForDeployment,
    onSuccess: async (response: ApiResponse) => {
      showSuccessSnackBar(response.message)
      queryClient.invalidateQueries(getDeploymentsQueryKey) // invalidate previous data, refetch, and rerender restaurant elevations table
    },
    onError: (err: Error) => {
      const errorText = `An error has occurred while deleting the restaurant elevations: ${err.message}`
      console.error(errorText)
      setErrorMessage(errorText)
    }
  })

  const {
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    mutate: mutateDeleteRestaurantElevations,
    reset: resetErrorDelete
  } = useMutation({
    mutationFn: deleteRestaurantElevations,
    onSuccess: async (response: ApiResponse) => {
      showSuccessSnackBar(response.message)
      queryClient.invalidateQueries(getDeploymentsQueryKey) // invalidate previous data, refetch, and rerender restaurant elevations table
    },
    onError: (err: Error) => {
      const errorText = `An error has occurred while deleting the restaurant elevations: ${err.message}`
      console.error(errorText)
      setErrorMessage(errorText)
    }
  })

  function handleDeleteRestaurantElevationClick(
    deploymentId: string,
    guid: string
  ) {
    setSelectedDeploymentId(deploymentId)
    setSelectedRestaurantGuid(guid)
    setIsDeleteRElevationModalOpen(true)
  }

  function handleDeleteAllRestaurantElevationsClick() {
    setIsDeleteAllRElevationModalOpen(true)
  }

  function handleLockClick() {
    setIsLocked(!isLocked)
  }

  const isLoading = isLoadingAdd || isLoadingDelete || isLoadingDeleteAll
  const isError = isErrorAdd || isErrorDelete || isErrorDeleteAll

  const deleteRestaurantElevationBody = (
    <>
      <p className='pb-4'>Confirm deletion of elevation for:</p>
      <p>
        <b>Deployment:</b> {`${selectedDeploymentId}`}
      </p>
      <p className='pb-8'>
        <b>Restaurant guid:</b> {`${selectedRestaurantGuid}`}
      </p>
    </>
  )

  return (
    <>
      <AddRestaurantElevationsModal
        isOpen={isRestaurantElevationsModalOpen}
        setIsOpen={setIsRestaurantElevationsModalOpen}
        activeDeployments={activeDeployments}
        onConfirm={mutateAddRestaurantElevations}
      />
      {/* Modal for deleting a single restaurant elevation */}
      <ConfirmModal
        isOpen={isDeleteRElevationModalOpen}
        setIsOpen={setIsDeleteRElevationModalOpen}
        onConfirm={async () => {
          mutateDeleteRestaurantElevations({
            deploymentId: selectedDeploymentId!,
            restaurantData: [selectedRestaurantGuid!]
          })
        }}
        title='Delete Restaurant Elevation'
        children={deleteRestaurantElevationBody}
        disableConfirmTextBox={true}
        size='lg'
      />
      {/* Modal for deleting all restaurant elevations for a deployment */}
      <DeleteAllRestaurantElevationsModal
        isOpen={isDeleteAllRElevationModalOpen}
        setIsOpen={setIsDeleteAllRElevationModalOpen}
        deploymentsWithRElevations={deploymentsWithRElevations}
        onConfirm={async () => {
          mutateDeleteAllRestaurantElevations({
            deploymentId: selectedDeploymentId!
          })
          setIsLocked(true)
        }}
      />
      <div className='flex justify-between mt-16 sm:w-full md:w-full lg:w-full xl:w-2/3'>
        <h2 className='type-headline-4 font-medium pb-2'>
          Restaurant Elevations
        </h2>
        <div>
          <IconButton
            onClick={handleLockClick}
            icon={
              isLocked ? (
                <LockLockedIcon
                  aria-label='unlock-rElevations-button-icon'
                  data-testid='unlock-rElevations-button'
                  className='mr-1 cursor-pointer text-gray-100 hover:text-secondary hover:bg-gray-25 rounded-full p-1'
                  size='sm'
                />
              ) : (
                <LockUnlockedIcon
                  aria-label='lock-rElevations-button-icon'
                  data-testid='lock-rElevations-button'
                  className='mr-1 cursor-pointer text-gray-100 hover:text-secondary hover:bg-gray-25 rounded-full p-1'
                  size='sm'
                />
              )
            }
          />
          <Button
            data-testid='remove-all-rElevations-button'
            variant='destructive'
            disabled={isLocked}
            className='text-center mb-4'
            onClick={handleDeleteAllRestaurantElevationsClick}
          >
            Delete All Restaurant Elevations for a Deployment
          </Button>
        </div>
      </div>
      <div
        data-testid={props.testId}
        className='border sm:w-full md:w-full lg:w-full xl:w-2/3'
      >
        <Table
          data-testid='restaurant-elevations-table'
          className='table-fixed'
          variant='plain'
          density='condensed'
        >
          <Head className='sticky top-0 z-10'>
            <Row>
              <HeadingCell className='w-28 pl-4'>Revision</HeadingCell>
              <HeadingCell className='w-32'>Deployment #</HeadingCell>
              <HeadingCell className='w-auto'>Guid</HeadingCell>
              <HeadingCell className='w-56 pl-16 pr-4'>
                Remove Elevations
              </HeadingCell>
            </Row>
          </Head>
          {restaurantElevations.length === 0 ? (
            <Body data-testid='restaurant-elevations-table-body'>
              <Row>
                <Cell colSpan={4}>
                  <p className='type-headline-5 text-secondary text-center py-12'>
                    There are no restaurant elevations.
                  </p>
                </Cell>
              </Row>
            </Body>
          ) : (
            <Body data-testid='restaurant-elevations-table-body'>
              {restaurantElevations.map((elevationRow, index) => (
                <Row key={index} className='bg-gray-0'>
                  <Cell className='pl-4'>{elevationRow.revision}</Cell>
                  <Cell>{elevationRow.deployment}</Cell>
                  <Cell>{elevationRow.restaurantElevationGuid}</Cell>
                  <Cell className='pl-16 text-center'>
                    <Tooltip content={() => 'Remove elevation'}>
                      <IconButton
                        icon={
                          <DeleteIcon
                            aria-label={`delete-rElevation-icon-${index}`}
                            data-testid={`delete-rElevation-icon-${index}`}
                            className='text-error hover:text-error-hover cursor-pointer mr-4'
                          />
                        }
                        onClick={() =>
                          handleDeleteRestaurantElevationClick(
                            `r${elevationRow.revision}-d${elevationRow.deployment}`,
                            elevationRow.restaurantElevationGuid
                          )
                        }
                      />
                    </Tooltip>
                  </Cell>
                </Row>
              ))}
            </Body>
          )}
        </Table>
      </div>
      {deploymentsByService.length !== 0 && (
        <Button
          data-testid='add-restaurant-elevations-button'
          className='mt-2'
          size='sm'
          iconLeft={<AddIcon aria-label='add-rElevation' />}
          onClick={() => setIsRestaurantElevationsModalOpen(true)}
          disabled={isLoading}
        >
          Add
        </Button>
      )}
      {isLoading && (
        <div
          data-testid='deployment-action-loading-spinner'
          className='fixed inset-0 bg-darken-56 z-50'
        >
          <MerryGoRound className='pin-center' size='md' />
        </div>
      )}
      {isError && (
        <div className='fixed bottom-0 inset-x-0 flex justify-center mb-4'>
          <Alert
            data-testid='error-alert'
            variant='error'
            onDismiss={() => {
              resetErrorAdd()
              resetErrorDelete()
              resetErrorDeleteAll()
              setErrorMessage('')
            }}
          >
            {errorMessage}
          </Alert>
        </div>
      )}
    </>
  )
}
