import React, { useState, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { appConfig } from '../../../../src/app/applicationConfig'
import { CancelIcon, SearchIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { ListItem } from '@toasttab/buffet-pui-dropdowns'
import { CardContainer } from '@toasttab/buffet-pui-card'

export interface SearchBarProps {
  testId?: string | number
  services: string[]
  setSelectedService: (service: string) => void
}

export const SearchBar = (props: SearchBarProps) => {
  const [filteredServices, setFilteredServices] = useState<string[]>([])
  const [textInputValue, setInputValue] = useState('')
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const navigate = useNavigate()

  const { services, setSelectedService } = props

  const handleSelectService = (service: string) => {
    setSelectedService(service)
    navigate(`${appConfig.spaBaseUrl}/${service}/deployments`)
    clearInputs()
  }

  function filterServices(query: string) {
    if (!query) {
      setFilteredServices(services)
    } else {
      const filtered = services.filter((service) =>
        service.toLowerCase().includes(query.toLowerCase())
      )
      setFilteredServices(filtered)
    }
  }

  function clearInputs() {
    setInputValue('')
    setFilteredServices([])
    setDropdownVisible(false)
  }

  function handleChangeText(event: ChangeEvent<HTMLInputElement>) {
    const text = event.target.value
    setInputValue(text)

    if (text.trim() === '') {
      clearInputs()
      return
    }

    filterServices(text)
    setDropdownVisible(filterServices.length > 0)
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const dropdownMenu = document.querySelector('.dropdown')
    const didClickMenu = dropdownMenu?.contains(event.relatedTarget)

    // clear all inputs if service selected, otherwise blur should leave text alone
    if (!dropdownVisible) {
      clearInputs()
    }

    if (!didClickMenu) {
      setDropdownVisible(false)
    }
  }

  function handleFocus(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value.trim() !== '') {
      setDropdownVisible(true)
    }
  }

  function handleDropdownFocus() {
    setDropdownVisible(true)
  }

  return (
    <div
      data-testid='search-bar'
      className='relative w-full mx-auto flex-grow pt-4'
    >
      <TextInput
        disabled={services.length === 0}
        className='w-full'
        autoComplete='off'
        prefix={<SearchIcon aria-label='Search' />}
        prefixVariant='icon'
        name='search-bar'
        onChange={handleChangeText}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder='Search by service name...'
        suffix={
          textInputValue && (
            <IconButton
              icon={<CancelIcon aria-label='Clear search' />}
              textClassName='text-gray-50 hover:text-gray-75 focus:text-gray-75'
              contained={true}
              onClick={clearInputs}
            />
          )
        }
        suffixVariant='iconButton'
        value={textInputValue}
      />
      {dropdownVisible && (
        <CardContainer
          data-testid='dropdown'
          noPadding
          className='dropdown py-2 absolute left-0 right-0 mt-1 max-h-60 overflow-y-auto z-20'
          onFocus={handleDropdownFocus}
        >
          {filteredServices.length > 0
            ? filteredServices.map((service, idx) => (
                <ListItem
                  key={idx}
                  label={service}
                  onClick={() => handleSelectService(service)}
                  tabIndex={-1}
                />
              ))
            : textInputValue.trim() !== '' && (
                <ListItem
                  label='No services found'
                  className='hover:bg-white hover:cursor-default'
                />
              )}
        </CardContainer>
      )}
    </div>
  )
}
