import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import {
  Table,
  Head,
  Body,
  Row,
  HeadingCell,
  Cell
} from '@toasttab/buffet-pui-table'
import { HelperText } from '@toasttab/buffet-pui-text-base'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import {
  ApiResponse,
  Deployment,
  DeploymentWithStringPercentages
} from '@local/types'
import { ConfirmModal } from '@local/service-elevations-modals'
import { DeploymentsApi, SetPercentageElevationsRequest } from '@local/api'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { Badge } from '@toasttab/buffet-pui-badge'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { getDeploymentsQueryKey, getPercentage } from '@local/utils'
import { LockLockedIcon, LockUnlockedIcon } from '@toasttab/buffet-pui-icons'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { SavePercentElevationsBody } from '@local/save-percent-elevations-body'

export interface PercentElevationsTableProps {
  testId?: string | number
  activeDeploymentsByService: Deployment[]
  deploymentsApi: DeploymentsApi
  serviceName: string
}

export const PercentElevationsTable = (props: PercentElevationsTableProps) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isAllPercentElevationsModalOpen, setIsAllPercentElevationsModalOpen] =
    useState(false)
  const { activeDeploymentsByService, deploymentsApi, serviceName } = props
  const [errorMessage, setErrorMessage] = useState('')
  const { showSuccessSnackBar } = useSnackBar()
  const [isLocked, setIsLocked] = useState(true)
  const queryClient = useQueryClient()

  // display percentages as whole numbers and map to string for ease of text editing
  const initialDeploymentsPctToString = activeDeploymentsByService.map(
    (deployment) => ({
      ...deployment,
      elevationPercentage: (deployment.elevationPercentage * 100).toString()
    })
  )
  const [activeDeployments, setActiveDeployments] = useState(
    initialDeploymentsPctToString
  )

  const initialPercentAllocated =
    getTotalPercentage(activeDeployments).toString()

  const [percentAllocated, setPercentAllocated] = useState(
    initialPercentAllocated
  )
  const [editedRows, setEditedRows] = useState<number[]>([])
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)

  function trackEditedRows(index: number, newPercentage: string) {
    newPercentage = getPercentage(newPercentage)

    if (
      newPercentage !== initialDeploymentsPctToString[index].elevationPercentage
    ) {
      setEditedRows((currentEditedRows: number[]) =>
        !currentEditedRows.includes(index)
          ? [...currentEditedRows, index]
          : currentEditedRows
      )
    } else {
      setEditedRows((currentEditedRows: number[]) =>
        currentEditedRows.filter((editedIndex) => editedIndex !== index)
      )
    }
  }

  function handleChangeText(index: number, percentage: string) {
    if (percentage === '' || /^[0-9]*$/.test(percentage)) {
      setActiveDeployments(() => {
        trackEditedRows(index, percentage)

        const updatedDeployments = activeDeployments.map((deployment, i) =>
          i === index
            ? { ...deployment, elevationPercentage: percentage }
            : deployment
        )

        const allocated = getTotalPercentage(updatedDeployments).toString()

        setPercentAllocated(allocated)
        setIsSaveButtonDisabled(allocated !== '100')
        return updatedDeployments
      })
    }
  }

  function getTotalPercentage(deployments: DeploymentWithStringPercentages[]) {
    return deployments.reduce((sum, deployment) => {
      return sum + Number(deployment.elevationPercentage)
    }, 0)
  }

  const editPercentElevations = useMutation({
    mutationKey: 'edit-percent-elevations',
    mutationFn: async (elevationsRequest: SetPercentageElevationsRequest) => {
      return deploymentsApi.updatePercentageElevations(
        serviceName,
        elevationsRequest
      )
    },
    onSuccess: async (response: ApiResponse) => {
      showSuccessSnackBar(response.message)
      queryClient.invalidateQueries(getDeploymentsQueryKey) // invalidate previous data, refetch, and rerender pct elevations table
    },
    onError: (err: Error) => {
      const errorText = `An error has occurred while updating the percentage elevations: ${err.message}`
      console.error(errorText)
      setErrorMessage(errorText)
    }
  })

  const removeAllPercentElevations = useMutation({
    mutationFn: async () => {
      return deploymentsApi.deleteAllPercentElevations(serviceName)
    },
    onSuccess: async (response: ApiResponse) => {
      showSuccessSnackBar(response.message)
      queryClient.invalidateQueries(getDeploymentsQueryKey) // invalidate previous deployment data, refetch, and rerender deployment table
    },
    onError: (err: Error) => {
      const errorText = `An error has occured while deleting percentage elevations: ${err.message}`
      console.error(errorText)
      setErrorMessage(errorText)
    }
  })

  function handleSaveElevations() {
    setIsConfirmModalOpen(true)
  }

  function mapDeploymentsToElevationRequest(
    deployments: DeploymentWithStringPercentages[]
  ): SetPercentageElevationsRequest {
    return deployments.reduce((result, deployment) => {
      const deploymentId = getDeploymentId(deployment)
      result[deploymentId] = Number(deployment.elevationPercentage) / 100
      return result
    }, {} as SetPercentageElevationsRequest)
  }

  function getDeploymentId(
    deployment: DeploymentWithStringPercentages
  ): string {
    const revision = deployment.revision
    const deploymentNumber = deployment.deployment
    return `r${revision}d${deploymentNumber}`
  }

  function handleLockClick() {
    setIsLocked(!isLocked)
  }

  function handleRemoveElevationsClick() {
    setIsAllPercentElevationsModalOpen(true)
  }

  const isLoading =
    removeAllPercentElevations.isLoading || editPercentElevations.isLoading

  const isError =
    removeAllPercentElevations.isError || editPercentElevations.isError

  const removePctElevationsTitle =
    'Confirm removal of all percentage elevations'
  const removePctElevationsBody = (
    <div>
      <p className='py-4'>
        This will stop all traffic to your service, with the exception of
        restaurant elevations.
      </p>
      <p className='pb-4'>{`Type "Yes I want to stop all traffic to ${serviceName}" to remove all percentage elevations.`}</p>
    </div>
  )

  return (
    <>
      <ConfirmModal
        isOpen={isAllPercentElevationsModalOpen}
        setIsOpen={setIsAllPercentElevationsModalOpen}
        onConfirm={async () => {
          removeAllPercentElevations.mutate()
          setIsLocked(true)
        }}
        children={removePctElevationsBody}
        title={removePctElevationsTitle}
        textPlaceholder={`Yes I want to stop all traffic to ${serviceName}`}
        confirmInputText={`Yes I want to stop all traffic to ${serviceName}`}
        size='lg'
      />
      <div className='flex justify-between sm:w-full md:w-full lg:w-full xl:w-2/3'>
        <h2 className='type-headline-4 font-medium pb-2'>
          Percentage Elevations
        </h2>
        {activeDeployments.length > 0 && (
          <div>
            <IconButton
              onClick={handleLockClick}
              icon={
                isLocked ? (
                  <LockLockedIcon
                    aria-label='unlock-percentages-button-icon'
                    data-testid='unlock-percentages-button'
                    className='mr-1 cursor-pointer text-gray-100 hover:text-secondary hover:bg-gray-25 rounded-full p-1'
                    size='sm'
                  />
                ) : (
                  <LockUnlockedIcon
                    aria-label='lock-percentages-button-icon'
                    data-testid='lock-percentages-button'
                    className='mr-1 cursor-pointer text-gray-100 hover:text-secondary hover:bg-gray-25 rounded-full p-1'
                    size='sm'
                  />
                )
              }
            />
            <Button
              data-testid='remove-all-pct-elevations-button'
              variant='destructive'
              disabled={isLocked}
              className='text-center mb-4'
              onClick={handleRemoveElevationsClick}
            >
              Remove All Percentage Elevations
            </Button>
          </div>
        )}
      </div>
      <div
        data-testid={props.testId}
        className='border sm:w-full md:w-full lg:w-full xl:w-2/3'
      >
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          setIsOpen={setIsConfirmModalOpen}
          data={mapDeploymentsToElevationRequest(activeDeployments)}
          onConfirm={async (
            elevationsRequest: SetPercentageElevationsRequest
          ) => {
            editPercentElevations.mutate(elevationsRequest)
          }}
          title='Confirm Percentage Changes'
        >
          <SavePercentElevationsBody
            editedRows={editedRows}
            initialDeploymentsPctToString={initialDeploymentsPctToString}
            activeDeployments={activeDeployments}
          />
        </ConfirmModal>
        <Table
          data-testid='percent-elevations-table'
          className='table-fixed'
          variant='plain'
          density='condensed'
        >
          <Head className='sticky top-0 z-10'>
            <Row>
              <HeadingCell className='w-28 pl-4'>Revision</HeadingCell>
              <HeadingCell className='w-32'>Deployment #</HeadingCell>
              <HeadingCell className='w-auto'>Tags</HeadingCell>
              <HeadingCell className='w-56 align-bottom text-right'>
                Percentage Traffic Set
                <InfoTooltip
                  className='pl-2 align-top text-right'
                  size='sm'
                  cropToIcon={true}
                  placement='top'
                >
                  Integers only, no decimals supported.
                </InfoTooltip>
              </HeadingCell>
            </Row>
          </Head>
          {activeDeployments.length === 0 ? (
            <Body data-testid='percent-elevations-table-body'>
              <Row>
                <Cell colSpan={4}>
                  <p className='type-headline-5 text-secondary text-center py-12'>
                    There are no active deployments.
                  </p>
                </Cell>
              </Row>
            </Body>
          ) : (
            <Body data-testid='percent-elevations-table-body'>
              {activeDeployments?.map((deployment, index) => (
                <Row key={index} className='bg-gray-0'>
                  <Cell className='pl-4'>{deployment.revision}</Cell>
                  <Cell>{deployment.deployment}</Cell>
                  <Cell>
                    {deployment.tags.map((tag, idx) => (
                      <Badge
                        key={idx}
                        color='neutral2'
                        badgeStyle='bold'
                        className='mx-px'
                      >
                        {tag}
                      </Badge>
                    ))}
                  </Cell>
                  <Cell className='pl-4'>
                    <TextInput
                      suffix='%'
                      containerClassName='w-24 float-right'
                      data-testid={`active-deployment-percentage-${index}`}
                      autoComplete='off'
                      value={`${deployment.elevationPercentage}`}
                      onChange={(e) => handleChangeText(index, e.target.value)}
                      disabled={isLoading}
                    />
                  </Cell>
                </Row>
              ))}
            </Body>
          )}
        </Table>
      </div>
      {activeDeployments.length !== 0 && (
        <div className='flex justify-between pt-2 sm:w-full md:w-full lg:w-full xl:w-2/3'>
          <div>
            <Button
              data-testid='save-pct-elevations-button'
              onClick={handleSaveElevations}
              disabled={
                isLoading || isSaveButtonDisabled || editedRows.length === 0
              }
              className={`${
                percentAllocated === '100' ? '' : 'border-2 border-error'
              }`}
            >
              Save
            </Button>
            {percentAllocated !== '100' ? (
              <p className='type-subhead font-medium text-error pt-1'>
                Percentages must total to 100%
              </p>
            ) : (
              <p className='pt-1 type-subhead font-medium invisible'>_</p>
            )}
          </div>
          <div className='flex flex-col items-center'>
            <div
              className={`justify-center ${
                percentAllocated === '100' ? 'border' : 'border-2 border-error'
              } rounded-input flex items-center font-semibold min-w-20 py-0 h-12 md:h-10 min-h-12 md:min-h-10 px-6 md:px-4`}
              data-testid='percentage-allocated-box'
            >
              {percentAllocated}
            </div>
            <HelperText helperText='% allocated' />
          </div>
        </div>
      )}
      {isLoading && (
        <div
          data-testid='update-elevations-loading-spinner'
          className='fixed inset-0 bg-darken-56 z-50'
        >
          <MerryGoRound className='pin-center' size='md' />
        </div>
      )}
      {isError && (
        <div className='fixed bottom-0 inset-x-0 flex justify-center mb-4'>
          <Alert
            data-testid='error-alert'
            variant='error'
            onDismiss={() => {
              removeAllPercentElevations.reset()
              editPercentElevations.reset()
              setErrorMessage('')
            }}
          >
            {errorMessage}
          </Alert>
        </div>
      )}
    </>
  )
}
