import { ApiUrls } from '../constants'

export class ApiClient {
  private baseUrl: string

  constructor(svcmgmtBaseUrl: string) {
    this.baseUrl = svcmgmtBaseUrl
  }

  async request<T>(url: ApiUrls, fetchOptions: RequestInit = {}): Promise<T> {
    const fullUrl = `${this.baseUrl}${url}`

    try {
      const response = await fetch(fullUrl, {
        headers: {
          'Content-Type': 'application/json'
        },
        ...fetchOptions
      })

      const responseBody = await response.json()
      if (response.status > 299) {
        throw new Error(responseBody.message)
      }

      return responseBody as T
    } catch (error) {
      console.log('Request failure: ', error)
      throw error
    }
  }
}
