import React, { useState, ChangeEvent, KeyboardEvent } from 'react'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { Label } from '@toasttab/buffet-pui-text-base'
import { Select } from '@toasttab/buffet-pui-select'
import {
  ApiResponse,
  Deployment,
  DeleteAllRestaurantElevationsMutationVariables
} from '@local/types'
import { UseMutateFunction } from 'react-query'
import { getActiveDeploymentIds } from '@local/utils'
import { TextInput } from '@toasttab/buffet-pui-text-input'

export interface DeleteAllRestaurantElevationsModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  deploymentsWithRElevations: Deployment[]
  onConfirm: UseMutateFunction<
    ApiResponse<string>,
    Error,
    DeleteAllRestaurantElevationsMutationVariables
  >
}

export const DeleteAllRestaurantElevationsModal = (
  props: DeleteAllRestaurantElevationsModalProps
) => {
  const { isOpen, setIsOpen, deploymentsWithRElevations, onConfirm } = props
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
  const [dropdownValue, setDropdownValue] = useState('')
  const [confirmText, setConfirmText] = useState('')

  function handleCancelModal() {
    setIsSubmitButtonDisabled(true)
    setDropdownValue('')
    setConfirmText('')
    setIsOpen(false)
  }

  function handleConfirm(deploymentId: string) {
    onConfirm({ deploymentId })
    handleCancelModal()
  }

  function handleChangeText(event: ChangeEvent<HTMLInputElement>) {
    const text = event.target.value

    setConfirmText(text)
  }

  function handleKeyPress(
    event: KeyboardEvent<HTMLInputElement>,
    deploymentId: string
  ) {
    if (event.key === 'Enter' && !isSubmitButtonDisabled) {
      handleConfirm(deploymentId)
    }
  }

  const isConfirmDisabled =
    dropdownValue === '' ||
    confirmText !== 'Delete all restaurant elevations for this deployment'

  return (
    <Modal
      testId='delete-restaurant-elevations-for-deployment-modal'
      className='flex justify-center'
      isOpen={isOpen}
      onRequestClose={handleCancelModal}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      size='md'
    >
      <ModalHeader>Delete Restaurant Elevations for Deployment</ModalHeader>
      <ModalBody className='flex flex-col justify-end min-h-32'>
        <div className='mb-6 w-3/4'>
          <Select
            testId='rElevation-revision-deployment-dropdown'
            options={getActiveDeploymentIds(deploymentsWithRElevations)}
            label='Target Revision and Deployment'
            placeholder='Choose Target'
            onChange={setDropdownValue}
            value={dropdownValue}
          />
        </div>
        <p className='pb-4'>
          This will remove all restaurant elevations from your deployment.
        </p>
        <p className='pb-4'>
          Type "Delete all restaurant elevations for this deployment" to delete.
        </p>
        <div className='mb-6'>
          <Label>Confirm Changes</Label>
          <TextInput
            className='h-28'
            testId='delete-rElevations-confirm-textbox'
            autoComplete='off'
            placeholder='Delete all restaurant elevations for this deployment'
            value={confirmText}
            onChange={handleChangeText}
            onKeyDown={(event) => handleKeyPress(event, dropdownValue)}
          />
        </div>
      </ModalBody>
      <ButtonGroup className='justify-center'>
        <Button
          testId='delete-rElevations-modal-cancel-button'
          variant='secondary'
          onClick={handleCancelModal}
        >
          Cancel
        </Button>
        <Button
          testId='delete-rElevations-modal-confirm-button'
          disabled={isConfirmDisabled}
          onClick={() => handleConfirm(dropdownValue)}
        >
          Confirm
        </Button>
      </ButtonGroup>
    </Modal>
  )
}
