import { RestaurantElevationsRowItem } from '@local/restaurant-elevations-table'
import {
  Deployment,
  DeploymentData,
  DeploymentWithStringPercentages,
  Service
} from '@local/types'

export const getDeploymentsQueryKey = 'getDeployments'

export function getActiveDeploymentIds(deployments: Deployment[]): string[] {
  return deployments.map((deployment) => getDeploymentId(deployment))
}

export function getDeploymentId(
  deployment: Deployment | DeploymentWithStringPercentages
): string {
  const revision = deployment.revision
  const deploymentNumber = deployment.deployment
  return `r${revision}d${deploymentNumber}`
}

export function populateRestaurantElevationList(
  deployments: Deployment[]
): RestaurantElevationsRowItem[] {
  const restaurantElevationList: RestaurantElevationsRowItem[] = []

  deployments.forEach((deployment) => {
    deployment.restaurantElevationGuids.forEach((guid) => {
      restaurantElevationList.push({
        revision: deployment.revision,
        deployment: deployment.deployment,
        restaurantElevationGuid: guid
      })
    })
  })

  return restaurantElevationList
}

export const filterByActiveDeployments = (
  deployments: Deployment[]
): Deployment[] => {
  if (!deployments) return []
  return deployments.filter((deployment) => deployment.active)
}

export const filterByRElevation = (deployments: Deployment[]): Deployment[] => {
  if (!deployments) return []
  return deployments.filter(
    (deployment) => deployment.restaurantElevationGuids.length > 0
  )
}

export const mapAndSortServiceList = (services: Service[]): string[] => {
  return services
    .map((service) => service.name)
    .sort((a: string, b: string) => a.localeCompare(b))
}

export const sortByRevisionAndDeployment = (
  deploymentData: DeploymentData
): Deployment[] => {
  return deploymentData.deployments.sort((a: Deployment, b: Deployment) => {
    if (a.revision === b.revision) {
      return a.deployment - b.deployment
    }
    return a.revision - b.revision
  })
}

export function getPercentage(percentage: string): string {
  return percentage === '' ? '0' : percentage
}
