import React, { useState, ChangeEvent, KeyboardEvent, ReactNode } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalSize
} from '@toasttab/buffet-pui-modal'
import { Label } from '@toasttab/buffet-pui-text-base'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'

type ConfirmFunction<T> = T extends void
  ? () => Promise<void>
  : (data: T) => Promise<void>

export interface ConfirmModalProps<T> {
  testId?: string | number
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  onConfirm: ConfirmFunction<T>
  data?: T
  title: string
  children?: ReactNode
  textPlaceholder?: string
  size?: ModalSize
  confirmInputText?: string
  disableConfirmTextBox?: boolean
}

export const ConfirmModal = <T,>(props: ConfirmModalProps<T>) => {
  const [confirmText, setConfirmText] = useState('')
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true)
  const {
    isOpen,
    setIsOpen,
    onConfirm,
    data,
    title,
    children,
    textPlaceholder = 'Type "confirm" if you are sure',
    size = 'sm',
    confirmInputText = 'confirm',
    disableConfirmTextBox = false
  } = props

  function handleChangeText(event: ChangeEvent<HTMLInputElement>) {
    const text = event.target.value

    setConfirmText(text)

    if (text.toLowerCase() === confirmInputText.toLowerCase()) {
      setIsConfirmButtonDisabled(false)
    } else {
      setIsConfirmButtonDisabled(true)
    }
  }

  function handleCancelModal() {
    setConfirmText('')
    setIsConfirmButtonDisabled(true)
    setIsOpen(false)
  }

  // if confirm function needs to post data, pass it in
  function handleConfirm() {
    if (data) {
      const confirmFn = onConfirm as (data: T) => Promise<void>
      confirmFn(data)
    } else {
      const confirmFn = onConfirm as () => Promise<void>
      confirmFn()
    }

    handleCancelModal()
  }

  function handleKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && !isConfirmButtonDisabled) {
      handleConfirm()
    }
  }

  return (
    <Modal
      testId='confirm-changes-modal'
      className='flex justify-center'
      isOpen={isOpen}
      onRequestClose={handleCancelModal}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      size={size}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody className='flex flex-col justify-center min-h-24'>
        <>
          {children}
          {disableConfirmTextBox ? null : (
            <div className='pb-8 mt-4'>
              <Label>Confirm Changes</Label>
              <TextInput
                testId='confirm-textbox'
                autoComplete='off'
                placeholder={textPlaceholder}
                value={confirmText}
                onChange={handleChangeText}
                onKeyDown={handleKeyPress}
              />
            </div>
          )}
        </>
      </ModalBody>
      <ButtonGroup className='justify-center'>
        <Button
          testId='modal-cancel-button'
          variant='secondary'
          onClick={handleCancelModal}
        >
          Cancel
        </Button>
        <Button
          testId='modal-confirm-button'
          disabled={!disableConfirmTextBox && isConfirmButtonDisabled}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </ButtonGroup>
    </Modal>
  )
}
