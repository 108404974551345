import { ApiResponse, DeploymentData } from '@local/types'
import { ApiClient } from '../ApiClient'
import { API_URLS } from '../constants'

export interface SetPercentageElevationsRequest {
  [deploymentId: string]: number
}

export class DeploymentsApi {
  private apiClient: ApiClient

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient
  }

  public getDeploymentsByService = async (
    serviceName: string
  ): Promise<DeploymentData> => {
    return await this.apiClient.request(API_URLS.DEPLOYMENTS(serviceName), {
      method: 'GET'
    })
  }

  public updatePercentageElevations = async (
    serviceName: string,
    elevationData: SetPercentageElevationsRequest
  ): Promise<ApiResponse> => {
    return await this.apiClient.request(API_URLS.DEPLOYMENTS(serviceName), {
      method: 'POST',
      body: JSON.stringify(elevationData)
    })
  }

  public activateDeployment = async (
    serviceName: string,
    deploymentId: string
  ): Promise<ApiResponse> => {
    return await this.apiClient.request(
      API_URLS.ACTIVATE_DEPLOYMENT(serviceName, deploymentId),
      {
        method: 'PUT'
      }
    )
  }

  public deactivateDeployment = async (
    serviceName: string,
    deploymentId: string
  ): Promise<ApiResponse> => {
    return await this.apiClient.request(
      API_URLS.DEACTIVATE_DEPLOYMENT(serviceName, deploymentId),
      {
        method: 'PUT'
      }
    )
  }

  public deleteAllPercentElevations = async (
    serviceName: string
  ): Promise<ApiResponse> => {
    return await this.apiClient.request(API_URLS.PCT_ELEVATIONS(serviceName), {
      method: 'DELETE'
    })
  }

  public addRestaurantElevations = async (
    serviceName: string,
    deploymentId: string,
    restaurantData: string[]
  ): Promise<ApiResponse> => {
    return await this.apiClient.request(
      API_URLS.ADD_RESTAURANT_ELEVATIONS(serviceName, deploymentId),
      {
        method: 'POST',
        body: JSON.stringify(restaurantData)
      }
    )
  }

  public deleteRestaurantElevations = async (
    serviceName: string,
    deploymentId: string,
    restaurantData: string[]
  ): Promise<ApiResponse> => {
    return await this.apiClient.request(
      API_URLS.DELETE_RESTAURANT_ELEVATIONS(serviceName, deploymentId),
      {
        method: 'POST',
        body: JSON.stringify(restaurantData)
      }
    )
  }

  public deleteAllRestaurantElevationsForDeployment = async (
    serviceName: string,
    deploymentId: string
  ): Promise<ApiResponse> => {
    return await this.apiClient.request(
      API_URLS.DELETE_ALL_RESTAURANT_ELEVATIONS_FOR_DEPLOYMENT(
        serviceName,
        deploymentId
      ),
      {
        method: 'DELETE'
      }
    )
  }
}
