import React, { useState } from 'react'
import '@local/themes/local/index.css'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
  HeadingGroup,
  Page,
  PageHeader,
  PageBody,
  Panel,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { SearchBar } from '@local/search-bar'
import { DeploymentTable } from '@local/deployment-table'
import { ServiceApi, DeploymentsApi } from '@local/api'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { PercentElevationsTable } from '@local/percent-elevations-table'
import { RestaurantElevationsTable } from '@local/restaurant-elevations-table'
import { Alert } from '@toasttab/buffet-pui-alerts'
import {
  filterByActiveDeployments,
  getDeploymentsQueryKey,
  mapAndSortServiceList,
  sortByRevisionAndDeployment
} from '@local/utils'

export interface HomePageProps {
  testId?: string | number
  serviceApi: ServiceApi
  deploymentsApi: DeploymentsApi
}

/**
 * HomePage component
 * Main container for the service elevations page
 */
export const HomePage = (props: HomePageProps) => {
  const [selectedService, setSelectedService] = useState<string>('')
  const params = useParams<{ serviceName: string }>()
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const { testId = 'elevations-home-page', serviceApi, deploymentsApi } = props

  const serviceName = params.serviceName || ''

  const { isLoading: isLoadingServices, data: services = [] } = useQuery({
    queryKey: ['service-list'],
    queryFn: serviceApi.getServices,
    select: mapAndSortServiceList,
    onSuccess: () => setShowError(false),
    onError: (err: Error) => {
      const errorText = `An error has occurred while fetching services: ${err.message}`
      console.error(errorText)
      setErrorMessage(errorText)
      setShowError(true)
    }
  })

  const serviceExists = services.some(
    (service) => service.toLowerCase() === serviceName.toLowerCase()
  )

  // Only fetch deployments if service name was in the url or selected, and if it exists
  const shouldFetchDeployments = Boolean(serviceName !== '' && serviceExists)

  const { isLoading: isLoadingDeployments, data: deploymentsByService = [] } =
    useQuery({
      queryKey: [getDeploymentsQueryKey, serviceName],
      queryFn: () => deploymentsApi.getDeploymentsByService(serviceName),
      select: sortByRevisionAndDeployment,
      enabled: shouldFetchDeployments,
      onSuccess: () => setShowError(false),
      onError: (err: Error) => {
        const errorText = `An error has occurred while fetching deployments for ${serviceName}: ${err.message}`
        console.error(errorText)
        setErrorMessage(errorText)
        setShowError(true)
      }
    })

  if (isLoadingServices) {
    return (
      <div data-testid='loading-spinner'>
        <MerryGoRound className='pin-center' />
      </div>
    )
  }

  // Searchbar usually sets the selected service, but if navigated to by direct url we'll need to set it
  if (serviceName !== '' && !selectedService) {
    setSelectedService(serviceName)
  }

  return (
    <div className='h-max' data-testid={testId}>
      <Page disableMaxWidth={true} className='w-full'>
        <PageHeader className='z-30 w-full pageheader'>
          <HeadingGroup className='w-full flex-1'>
            <h2 className='pt-3 type-large'>
              {/* serviceExists is always false when page initially loads. Don't show "Could not find service" message if loading homepage without a selected service */}
              {serviceExists || selectedService === ''
                ? serviceName
                : `Could not find service with name: ${serviceName}`}
            </h2>
            <Title className='w-full'>Service Elevations</Title>
          </HeadingGroup>
          <div className='flex flex-2 w-1/2 items-center'>
            <SearchBar
              services={services || []}
              setSelectedService={setSelectedService}
            />
          </div>
        </PageHeader>
        <PageBody className='lg:px-12 md:px-12 px-12 h-max pagebody'>
          <Panel className='h-max md:h-max lg:h-max min-h-128'>
            {selectedService.length > 0 ? (
              isLoadingDeployments ? (
                <div data-testid='loading-spinner-deployments'>
                  <MerryGoRound className='pin-center' />
                </div>
              ) : (
                <>
                  <DeploymentTable
                    serviceName={serviceName}
                    deploymentsByService={deploymentsByService}
                    serviceExists={serviceExists}
                    deploymentsApi={deploymentsApi}
                    serviceApi={serviceApi}
                  />
                  <PercentElevationsTable
                    activeDeploymentsByService={filterByActiveDeployments(
                      deploymentsByService
                    )}
                    deploymentsApi={deploymentsApi}
                    serviceName={serviceName}
                  />
                  <RestaurantElevationsTable
                    deploymentsByService={deploymentsByService || []}
                    deploymentsApi={deploymentsApi}
                    serviceName={serviceName}
                  />
                </>
              )
            ) : (
              <div className='h-max flex items-center justify-center'>
                <p className='type-headline-5 text-secondary text-center pt-32 pb-64'>
                  {!services || services?.length === 0
                    ? 'Unable to load service data. Try refreshing.'
                    : 'Select a service in the search bar above.'}
                </p>
              </div>
            )}
          </Panel>
        </PageBody>
      </Page>
      {showError && (
        <div className='fixed bottom-0 inset-x-0 flex justify-center mb-4'>
          <Alert
            data-testid='error-alert'
            variant='error'
            onDismiss={() => {
              setShowError(false)
              setErrorMessage('')
            }}
          >
            {errorMessage}
          </Alert>
        </div>
      )}
    </div>
  )
}
