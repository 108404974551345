import React, { useState, ChangeEvent } from 'react'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { Label } from '@toasttab/buffet-pui-text-base'
import { Select } from '@toasttab/buffet-pui-select'
import { Textarea } from '@toasttab/buffet-pui-text-area'
import {
  RestaurantElevationMutationVariables,
  ApiResponse,
  Deployment
} from '@local/types'
import { UseMutateFunction } from 'react-query'
import { getActiveDeploymentIds } from '@local/utils'

export interface AddRestaurantElevationsModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  activeDeployments: Deployment[]
  onConfirm: UseMutateFunction<
    ApiResponse<string>,
    Error,
    RestaurantElevationMutationVariables
  >
}

function parseTextField(restaurantIdTextInput: string): string[] {
  // remove newlines and separate by comma
  const restaurantIds = restaurantIdTextInput
    .replace(/\s*[\r\n]+\s*/g, '')
    .split(',')

  return restaurantIds.map((id) => id.trim()).filter((id) => id.length > 0)
}

export const AddRestaurantElevationsModal = (
  props: AddRestaurantElevationsModalProps
) => {
  const { isOpen, setIsOpen, activeDeployments, onConfirm } = props
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
  const [dropdownValue, setDropdownValue] = useState('')
  const [restaurantIdText, setRestaurantIdText] = useState('')

  function handleCancelModal() {
    setIsSubmitButtonDisabled(true)
    setDropdownValue('')
    setRestaurantIdText('')
    setIsOpen(false)
  }

  function handleConfirm(deploymentId: string, restaurantData: string[]) {
    onConfirm({ deploymentId, restaurantData })
    handleCancelModal()
  }

  function handleChangeText(event: ChangeEvent<HTMLTextAreaElement>) {
    const text = event.target.value

    setRestaurantIdText(text)

    if (text === '') {
      setIsSubmitButtonDisabled(true)
    } else if (dropdownValue !== '') {
      setIsSubmitButtonDisabled(false)
    }
  }

  function handleDropdownChange(value: string) {
    setDropdownValue(value)

    if (restaurantIdText !== '') {
      setIsSubmitButtonDisabled(false)
    }
  }

  return (
    <Modal
      testId='add-restaurant-elevations-modal'
      className='flex justify-center'
      isOpen={isOpen}
      onRequestClose={handleCancelModal}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      size='md'
    >
      <ModalHeader>Add Restaurant Elevations</ModalHeader>
      <ModalBody className='flex flex-col justify-end min-h-32'>
        <div className='mb-8 w-3/4'>
          <Select
            testId='revision-deployment-dropdown'
            options={getActiveDeploymentIds(activeDeployments)}
            label='Target Revision and Deployment'
            placeholder='Choose Target'
            onChange={(value) => handleDropdownChange(value)}
            value={dropdownValue}
          />
        </div>
        <div className='pb-8'>
          <Label>Restaurant GUIDs</Label>
          <Textarea
            className='h-28'
            testId='restaurant-ids-textbox'
            autoComplete='off'
            placeholder='Enter restaurant GUIDs, separated by commas'
            value={restaurantIdText}
            onChange={handleChangeText}
          />
        </div>
      </ModalBody>
      <ButtonGroup className='justify-center'>
        <Button
          testId='restaurant-modal-cancel-button'
          variant='secondary'
          onClick={handleCancelModal}
        >
          Cancel
        </Button>
        <Button
          testId='restaurant-modal-save-button'
          disabled={isSubmitButtonDisabled}
          onClick={() =>
            handleConfirm(dropdownValue, parseTextField(restaurantIdText))
          }
        >
          Save
        </Button>
      </ButtonGroup>
    </Modal>
  )
}
