import * as React from 'react'
import { Body, Cell, Row, Table } from '@toasttab/buffet-pui-table'
import { getDeploymentId, getPercentage } from '@local/utils'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { DeploymentWithStringPercentages } from '@local/types'

export interface SavePercentElevationsBodyProps {
  editedRows: number[]
  initialDeploymentsPctToString: DeploymentWithStringPercentages[]
  activeDeployments: DeploymentWithStringPercentages[]
}

export const SavePercentElevationsBody = (
  props: SavePercentElevationsBodyProps
) => {
  const { editedRows, initialDeploymentsPctToString, activeDeployments } = props

  function createPctComparisonString(row: number) {
    const initialPercent = getPercentage(
      initialDeploymentsPctToString[row].elevationPercentage
    )
    const editedPercent = getPercentage(
      activeDeployments[row].elevationPercentage
    )

    return (
      <p>
        {initialPercent}%{' '}
        <ArrowForwardIcon aria-label='percent-summary-arrow' /> {editedPercent}%
      </p>
    )
  }

  function isPercentIncreasing(row: number): boolean {
    const initialPercent = getPercentage(
      initialDeploymentsPctToString[row].elevationPercentage
    )
    const editedPercent = getPercentage(
      activeDeployments[row].elevationPercentage
    )

    return Number(initialPercent) < Number(editedPercent)
  }

  return (
    <>
      {editedRows.length > 0 && (
        <div className='max-h-64 overflow-auto mt-4'>
          <Table variant='plain' data-testid='summary-of-pct-changes-table'>
            <Body>
              {editedRows.sort().map((row) => (
                <Row
                  key={row}
                  className={`${
                    isPercentIncreasing(row) ? 'bg-success-0' : 'bg-error-0'
                  } flex justify-between border-white`}
                >
                  <Cell className='w-20'>
                    {getDeploymentId(initialDeploymentsPctToString[row])}
                  </Cell>
                  <Cell className='w-36 text-right'>
                    {createPctComparisonString(row)}
                  </Cell>
                </Row>
              ))}
            </Body>
          </Table>
        </div>
      )}
    </>
  )
}
