import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BanquetProps } from 'banquet-runtime-modules'
import { HomePage } from '../../packages/home-page'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { ErrorPage404 } from '@toasttab/buffet-pui-error-pages'
import { HomeIcon } from '@toasttab/buffet-pui-icons'
import { appConfig } from './applicationConfig'
import { ServiceApi, DeploymentsApi, ApiClient } from '@local/api'
import {
  getDeploymentsByServiceMock,
  updatePercentageElevationsMock,
  getServicesMock,
  activateDeploymentMock,
  deactivateDeploymentMock,
  deleteAllPercentElevationsMock,
  decommissionServiceMock,
  addRestaurantElevationsMock,
  deleteRestaurantElevationsMock,
  deleteAllRestaurantElevationsMock
} from '@local/service-elevations-mocks'

// TODO: Remove eslint disable once actually passing props
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function App(props?: BanquetProps) {
  const apiClient = new ApiClient(appConfig.svcmgmtBaseUrl)
  const serviceApi = new ServiceApi(apiClient)
  const deploymentsApi = new DeploymentsApi(apiClient)

  if (process.env.NODE_ENV === 'development') {
    console.log('Rendering in development mode...')

    serviceApi.getServices = getServicesMock
    serviceApi.decommissionService = decommissionServiceMock

    deploymentsApi.getDeploymentsByService = getDeploymentsByServiceMock
    deploymentsApi.updatePercentageElevations = updatePercentageElevationsMock
    deploymentsApi.activateDeployment = activateDeploymentMock
    deploymentsApi.deactivateDeployment = deactivateDeploymentMock
    deploymentsApi.deleteAllPercentElevations = deleteAllPercentElevationsMock
    deploymentsApi.addRestaurantElevations = addRestaurantElevationsMock
    deploymentsApi.deleteRestaurantElevations = deleteRestaurantElevationsMock
    deploymentsApi.deleteAllRestaurantElevationsForDeployment =
      deleteAllRestaurantElevationsMock
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {},
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchInterval: false,
        refetchIntervalInBackground: false
      }
    }
  })

  const homeRedirect = [
    {
      href: appConfig.spaBaseUrl,
      icon: <HomeIcon aria-label='Home' />,
      label: 'Home'
    }
  ]

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <SnackBarProvider>
          <Routes>
            <Route
              path={`${appConfig.spaBaseUrl}/:serviceName/deployments`}
              element={
                <HomePage
                  serviceApi={serviceApi}
                  deploymentsApi={deploymentsApi}
                />
              }
            />
            <Route
              path={`${appConfig.spaBaseUrl}/404`}
              element={<ErrorPage404 links={homeRedirect} />}
            />
            <Route
              path={appConfig.spaBaseUrl}
              element={
                <HomePage
                  serviceApi={serviceApi}
                  deploymentsApi={deploymentsApi}
                />
              }
            />
            <Route
              path={`${appConfig.spaBaseUrl}/*`}
              element={<Navigate to={`${appConfig.spaBaseUrl}/404`} />}
            />
          </Routes>
        </SnackBarProvider>
      </QueryClientProvider>
    </Router>
  )
}
