import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import { App } from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-service-elevations',
  rootComponent: App,
  sentry: {
    publicKey: 'e066c5f07706da700b7b97e2f900818f@o37442',
    projectId: '4507386973061120',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  },
  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'service-elevations-spa'
