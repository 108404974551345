import React, { useState } from 'react'
import {
  Table,
  Head,
  Body,
  Row,
  HeadingCell,
  Cell
} from '@toasttab/buffet-pui-table'
import {
  ExpandIcon,
  LockLockedIcon,
  LockUnlockedIcon
} from '@toasttab/buffet-pui-icons'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import {
  ActivateMutationVariables,
  ApiResponse,
  Deployment
} from '@local/types'
import { ConfirmModal } from '@local/service-elevations-modals'
import { Badge } from '@toasttab/buffet-pui-badge'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { DeploymentsApi, ServiceApi } from '@local/api'
import { getDeploymentsQueryKey, getDeploymentId } from '@local/utils'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Alert } from '@toasttab/buffet-pui-alerts'

export interface DeploymentTableProps {
  testId?: string | number
  serviceName: string
  deploymentsByService: Deployment[]
  serviceExists: boolean
  deploymentsApi: DeploymentsApi
  serviceApi: ServiceApi
}

export const DeploymentTable = (props: DeploymentTableProps) => {
  const queryClient = useQueryClient()
  const [isLocked, setIsLocked] = useState(true)
  const [expandedRows, setExpandedRows] = useState<number[]>([])
  const [isDecommissionModalOpen, setIsDecommissionModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [selectedDeploymentId, setSelectedDeploymentId] = useState<
    string | null
  >(null)
  const [isActivating, setIsActivating] = useState<boolean | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const { showSuccessSnackBar } = useSnackBar()

  const {
    deploymentsByService,
    serviceName,
    serviceExists,
    deploymentsApi,
    serviceApi
  } = props

  function handleRowClick(rowIndex: number) {
    if (expandedRows.includes(rowIndex)) {
      setExpandedRows(expandedRows.filter((row) => row !== rowIndex))
    } else {
      setExpandedRows([...expandedRows, rowIndex])
    }
  }

  function handleActionButtonClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    deploymentId: string,
    activating: boolean
  ) {
    // prevent button click from expanding the row
    event.stopPropagation()

    setSelectedDeploymentId(deploymentId)
    setIsActivating(activating)
    setIsConfirmModalOpen(true)
  }

  function handleDecommissionServiceClick() {
    setIsDecommissionModalOpen(true)
  }

  function handleLockClick() {
    setIsLocked(!isLocked)
  }

  function updateActivationStatus({
    deploymentId,
    isActivating
  }: ActivateMutationVariables) {
    return isActivating
      ? deploymentsApi.activateDeployment(serviceName, deploymentId)
      : deploymentsApi.deactivateDeployment(serviceName, deploymentId)
  }

  const editActiveStatus = useMutation({
    mutationFn: updateActivationStatus,
    onSuccess: async (response: ApiResponse) => {
      showSuccessSnackBar(response.message)
      queryClient.invalidateQueries(getDeploymentsQueryKey) // invalidate previous deployment data, refetch, and rerender deployment table
    },
    onError: (err: Error) => {
      const errorText = `An error has occured while updating the activation status: ${err.message}`
      console.error(errorText)
      setErrorMessage(errorText)
    }
  })

  const decommissionService = useMutation({
    mutationFn: async () => {
      return serviceApi.decommissionService(serviceName)
    },
    onSuccess: async (response: ApiResponse) => {
      showSuccessSnackBar(response.message)
      queryClient.invalidateQueries(getDeploymentsQueryKey) // invalidate previous deployment data, refetch, and rerender deployment table
    },
    onError: (err: Error) => {
      const errorText = `An error has occured while attempting to decommission ${serviceName}: ${err.message}`
      console.error(errorText)
      setErrorMessage(errorText)
    }
  })

  const decommissionServiceTitle = `Are you sure you want to decommission ${serviceName}?`
  const decommissionServiceBody = (
    <p className='py-4'>
      {`This will remove the service completely from svcmgmt. Type "Yes I want to
      completely remove ${serviceName}" if you are sure.`}
    </p>
  )
  const decommissionConfirmText = `Yes I want to completely remove ${serviceName}`

  const activateModalTitle = isActivating
    ? `Confirm Activation for ${selectedDeploymentId}`
    : `Confirm Deactivation for ${selectedDeploymentId}`

  const isLoading = editActiveStatus.isLoading || decommissionService.isLoading
  const isError = editActiveStatus.isError || decommissionService.isError

  return (
    <>
      {/* Confirm modal for decommissioning a service */}
      <ConfirmModal
        isOpen={isDecommissionModalOpen}
        setIsOpen={setIsDecommissionModalOpen}
        onConfirm={async () => {
          decommissionService.mutate()
          setIsLocked(true)
        }}
        title={decommissionServiceTitle}
        children={decommissionServiceBody}
        size='md'
        textPlaceholder={decommissionConfirmText}
        confirmInputText={decommissionConfirmText}
      />
      {/* Confirm modal for activating/deactivating a deployment */}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={async () => {
          editActiveStatus.mutate({
            deploymentId: selectedDeploymentId!,
            isActivating: isActivating!
          })
        }}
        title={activateModalTitle}
      />
      {deploymentsByService.length === 0 && serviceExists && (
        <div className='flex flex-row items-start justify-end h-full'>
          <IconButton
            onClick={handleLockClick}
            icon={
              isLocked ? (
                <LockLockedIcon
                  aria-label='unlock-decommission-button-icon'
                  data-testid='unlock-decommission-button'
                  className='mr-1 cursor-pointer text-gray-100 hover:text-secondary hover:bg-gray-25 rounded-full p-1'
                  size='sm'
                />
              ) : (
                <LockUnlockedIcon
                  aria-label='lock-decommission-button-icon'
                  data-testid='lock-decommission-button'
                  className='mr-1 cursor-pointer text-gray-100 hover:text-secondary hover:bg-gray-25 rounded-full p-1'
                  size='sm'
                />
              )
            }
          />
          <Button
            data-testid='decommission-service-button'
            variant='destructive'
            disabled={isLocked}
            className='text-center mb-4'
            onClick={handleDecommissionServiceClick}
          >
            Decommission Service
          </Button>
        </div>
      )}
      <h2 className='type-headline-4 font-medium pb-2'>Service Deployments</h2>
      <div className='mb-16 border'>
        <Table
          data-testid='deployments-table'
          className='table-fixed'
          variant='plain'
          density='condensed'
        >
          <Head className='sticky top-0 z-10'>
            <Row>
              <HeadingCell className='w-28 pl-4'>Revision</HeadingCell>
              <HeadingCell className='w-32'>Deployment #</HeadingCell>
              <HeadingCell className='w-32'>Status</HeadingCell>
              <HeadingCell className='w-36'>Tags</HeadingCell>
              <HeadingCell className='w-auto pr-8'>Comments</HeadingCell>
              <HeadingCell className='w-36 text-center'>Actions</HeadingCell>
              <HeadingCell className='w-12'></HeadingCell>
            </Row>
          </Head>
          {deploymentsByService.length === 0 ? (
            <Body data-testid='deployments-table-body'>
              <Row>
                <Cell colSpan={7}>
                  <p className='type-headline-5 text-secondary text-center py-12'>
                    There are no deployments.
                  </p>
                </Cell>
              </Row>
            </Body>
          ) : (
            <Body data-testid='deployments-table-body'>
              {deploymentsByService?.map((deployment, index) => (
                <React.Fragment key={index}>
                  <Row
                    data-testid={`expandable-row-${index}`}
                    className='border-y bg-gray-0 hover:bg-darken-8'
                    onClick={() => handleRowClick(index)}
                  >
                    <Cell className='pl-4'>{deployment.revision}</Cell>
                    <Cell>{deployment.deployment}</Cell>
                    <Cell>
                      {deployment.active ? (
                        <Badge color='success' badgeStyle='bold'>
                          Active
                        </Badge>
                      ) : (
                        <Badge color='info' badgeStyle='bold'>
                          Inactive
                        </Badge>
                      )}
                    </Cell>
                    <Cell>
                      {deployment.tags.map((tag, idx) => (
                        <Badge
                          key={idx}
                          color='neutral2'
                          badgeStyle='bold'
                          className='mx-px'
                        >
                          {tag}
                        </Badge>
                      ))}
                    </Cell>
                    <Cell className='pr-8'>{deployment.comments}</Cell>
                    <Cell className='flex justify-end w-36' colSpan={1}>
                      {deployment.active ? (
                        <Button
                          onClick={(
                            event: React.MouseEvent<
                              HTMLButtonElement,
                              MouseEvent
                            >
                          ) =>
                            handleActionButtonClick(
                              event,
                              getDeploymentId(deployment),
                              false
                            )
                          }
                          className='min-w-24 mr-3 type-subhead'
                          variant='destructive-secondary'
                          data-testid={`deactivate-button-${index}`}
                          size='sm'
                        >
                          Deactivate
                        </Button>
                      ) : (
                        <Button
                          onClick={(
                            event: React.MouseEvent<
                              HTMLButtonElement,
                              MouseEvent
                            >
                          ) =>
                            handleActionButtonClick(
                              event,
                              getDeploymentId(deployment),
                              true
                            )
                          }
                          className='min-w-24 mr-3 type-subhead px-6 sm:px-6 md:px-6 lg:px-6'
                          variant='secondary'
                          data-testid={`activate-button-${index}`}
                        >
                          Activate
                        </Button>
                      )}
                    </Cell>
                    <Cell className='p-0'>
                      <ExpandIcon
                        size='sm'
                        className='text-secondary mr-2'
                        isExpanded={expandedRows.includes(index)}
                        accessibility='decorative'
                      />
                    </Cell>
                  </Row>
                  {expandedRows.includes(index) && (
                    <Row
                      data-testid={`expanded-row-${index}`}
                      className='bg-white'
                    >
                      <Cell colSpan={6} className='px-16'>
                        <div className='pb-2'>
                          <Table
                            className='table-fixed'
                            variant='plain'
                            density='condensed'
                          >
                            <Body className='border-none'>
                              <Row className='border-none'>
                                <Cell className='pt-1 pb-0'>
                                  <span className='text-secondary type-subhead pb-2 mr-2'>
                                    Build Hash:
                                  </span>
                                  <span className='type-subhead text-link hover:text-link-hover hover:underline'>
                                    <a
                                      href={`https://tdp.eng.toasttab.com/${serviceName}/builds/${deployment.buildHash}`}
                                      target='_blank'
                                      rel='noreferrer'
                                    >
                                      {deployment.buildHash}
                                    </a>
                                  </span>
                                </Cell>
                              </Row>
                              <Row className='border-none flex w-full'>
                                <Cell className='w-60'>
                                  <span className='text-secondary type-subhead pb-2 mr-2'>
                                    Deployed On:
                                  </span>
                                  <span className='type-subhead'>
                                    {deployment.createdAt}
                                  </span>
                                </Cell>
                                <Cell className='w-auto whitespace-nowrap'>
                                  <span className='text-secondary type-subhead pb-2 mr-2'>
                                    Deployed By:
                                  </span>
                                  <span className='type-subhead'>
                                    {deployment.createdBy}
                                  </span>
                                </Cell>
                              </Row>
                            </Body>
                          </Table>
                        </div>
                      </Cell>
                    </Row>
                  )}
                </React.Fragment>
              ))}
            </Body>
          )}
        </Table>
      </div>
      {isLoading && (
        <div
          data-testid='deployment-action-loading-spinner'
          className='fixed inset-0 bg-darken-56 z-50'
        >
          <MerryGoRound className='pin-center' size='md' />
        </div>
      )}
      {isError && (
        <div className='fixed bottom-0 inset-x-0 flex justify-center mb-4'>
          <Alert
            data-testid='error-alert'
            variant='error'
            onDismiss={() => {
              editActiveStatus.reset()
              decommissionService.reset()
              setErrorMessage('')
            }}
          >
            {errorMessage}
          </Alert>
        </div>
      )}
    </>
  )
}
