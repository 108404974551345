export const API_URLS = {
  SERVICES: '/v5/services',
  DECOMMISSION_SERVICE: (serviceName: string) => `/v5/services/${serviceName}`,
  DEPLOYMENTS: (serviceName: string) =>
    `/v5/services/${serviceName}/deployments`,
  PCT_ELEVATIONS: (serviceName: string) =>
    `/v5/services/${serviceName}/elevations/percentage`,
  ACTIVATE_DEPLOYMENT: (serviceName: string, deploymentId: string) =>
    `/v5/services/${serviceName}/deployments/${deploymentId}/activate`,
  DEACTIVATE_DEPLOYMENT: (serviceName: string, deploymentId: string) =>
    `/v5/services/${serviceName}/deployments/${deploymentId}/deactivate`,
  ADD_RESTAURANT_ELEVATIONS: (serviceName: string, deploymentId: string) =>
    `/v5/services/${serviceName}/elevations/restaurant/${deploymentId}/add`,
  DELETE_RESTAURANT_ELEVATIONS: (serviceName: string, deploymentId: string) =>
    `/v5/services/${serviceName}/elevations/restaurant/${deploymentId}/delete`,
  DELETE_ALL_RESTAURANT_ELEVATIONS_FOR_DEPLOYMENT: (
    serviceName: string,
    deploymentId: string
  ) => `/v5/services/${serviceName}/elevations/restaurant/${deploymentId}/all`
}

export type ApiUrls = (typeof API_URLS)[keyof typeof API_URLS]
