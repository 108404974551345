import { ApiResponse, Service } from '@local/types'
import { ApiClient } from '../ApiClient'
import { API_URLS } from '../constants'

export class ServiceApi {
  private apiClient: ApiClient

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient
  }

  public getServices = async (): Promise<Service[]> => {
    return this.apiClient.request(API_URLS.SERVICES, { method: 'GET' })
  }

  public decommissionService = async (
    serviceName: string
  ): Promise<ApiResponse> => {
    return this.apiClient.request(API_URLS.DECOMMISSION_SERVICE(serviceName), {
      method: 'DELETE'
    })
  }
}
